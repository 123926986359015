<template>
  <el-tiptap :extensions="extensions" v-model="cont" placeholder="不限" />
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  Iframe, //视频
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  // LineHeight,
  // CodeBlock,
  // TrailingNode,
  // Table, // use with TableHeader, TableCell, TableRow
  // TableHeader,
  // TableCell,
  // TableRow,
  // FormatClear,
  // TextColor,
  // TextHighlight,
  // Preview,
  // Print,
  Fullscreen
  // CodeView
  // SelectAll,
} from 'element-tiptap'

// import codemirror from 'codemirror'
import 'codemirror/lib/codemirror.css' // import base style
import 'codemirror/mode/xml/xml.js' // language
import 'codemirror/addon/selection/active-line.js' // require active-line.js
import 'codemirror/addon/edit/closetag.js' // autoCloseTags

export default {
  name: 'Editor',
  props: {
    content: String
  },
  data() {
    return {
      cont: this.content,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Link({ bubble: true }),
        new Image({ uploadRequest: this.uploadImage }),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new Iframe(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule({ bubble: true }),
        new Fullscreen(),
        // new CodeView({
        //   codemirror,
        //   codemirrorOptions: {
        //     styleActiveLine: true,
        //     autoCloseTags: true
        //   }
        // }),
        new History()
      ]
    }
  },
  watch: {
    content(val) {
      this.cont = val
    },
    cont(val) {
      this.$emit('getContent', val)
    }
  },
  methods: {
    uploadImage(file) {
      const fd = new FormData()
      fd.append('file', file)
      return this.$api.property.uploadImage(fd).then(res => {
        const url = this.$constants.common.imgPrefix + res.data.data
        //let url = 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=915144082,2622891307&fm=26&gp=0.jpg'
        //let url = 'http://community.luopan88.com/jpg/20210519094834239_tmp_828852dbd39ac8a1f07ae58c15bd77ae.jpg'
        return url
      })
    }
  }
}
</script>
<style lang="scss">
.el-tiptap-editor__content .image-view__body__image {
  max-height: 600px;
  min-height: 200px;
}
</style>
